// External packages
import * as React from "react"
import { createPortal } from "react-dom"
import { useProducts } from "medusa-react"
import { Box, BoxProps, Flex, Heading, Image, Paragraph, Text } from "theme-ui"
import { kebabCase } from "lodash"
import { IGatsbyImageData } from "gatsby-plugin-image"

// Types
import { ContentfulShopTheLook } from "types"

// Hooks
import useLockedBody from "../../hooks/v2/useLockedBody"

// Contexts
import { useStore } from "../../context/NewStoreContext"
import { useNotificationContext } from "../../context/NotificationContext"
import { useInterfaceContext } from "../../context/InterfaceContext"

// Utilities
import { formatProductPrice } from "../../utils/formatProductPrice"
import { getProductTitleWithColor } from "../../utils/getProductTitleWithColor"
import { isProductOutOfStock } from "../v2/utils/product/isProductOutOfStock"
import { isVariantOutOfStock } from "../v2/utils/product/isVariantOutOfStock"
import { getVariantsInventory } from "../v2/utils/product/getVariantsInventory"
import { handleAddToCart } from "../v2/utils/product/handleAddToCart"

// Components
import { Link } from "../v2/Link"
import { Button } from "../v2/Button"
import { ProductButton } from "../v2/product/ProductButton"
import { SizePickerDrawer } from "../v2/product/SizePickerDrawer"
import { NotifyMeDrawer } from "../v2/product/NotifyMeDrawer"
import { UiButton } from "../v2/ui/Button"
import { Drawer } from "../v2/ui/Drawer"
import { Icon } from "../v2/ui/Icon"
import { UiCategoryGrid } from "../../templates/medusa/CategoryTemplate"
import { Picture } from "./Picture"
import { getBunnyImageSrc } from "../../utils/bunny-cdn"

export const ShopTheLook: React.FC<{
  items: ContentfulShopTheLook[]
  pageTitle?: string
  pageDescription?: string
  hasTopGrid: boolean | null
}> = ({ items, pageTitle, pageDescription, hasTopGrid }) => {
  const shopTheLookPhotosRefs = React.useRef([])
  const shopTheLookContentRefs = React.useRef([])

  function getVisibleItemIndex(
    array: number[],
    scrollPosition: number
  ): number {
    const index = array.findIndex((position) => scrollPosition < position)
    return index === -1 ? array.length - 1 : index - 1
  }

  React.useEffect(() => {
    const backToTopElement = document.querySelector(".js-back-to-top")
    const gridElement = document.querySelector(".js-grid") as HTMLElement
    const shopTheLookPositions = shopTheLookPhotosRefs.current.map(
      (element) => element.offsetTop
    )
    const mobileContentElements =
      document.querySelectorAll(".js-mobile-content")

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2
      const visibleElement = shopTheLookContentRefs.current[
        getVisibleItemIndex(shopTheLookPositions, scrollPosition)
      ] as HTMLElement

      shopTheLookContentRefs.current.forEach((element) => {
        element.setAttribute("data-visible", "false")
      })
      visibleElement?.setAttribute("data-visible", "true")

      if (scrollPosition > gridElement?.offsetTop + gridElement?.offsetHeight) {
        backToTopElement.setAttribute("data-visible", "true")
      } else {
        backToTopElement.setAttribute("data-visible", "false")
      }

      mobileContentElements.forEach((element: HTMLDivElement) => {
        if (
          document.documentElement.scrollTop < 200 &&
          element.dataset.visible === "true"
        ) {
          element.setAttribute("data-visible", "false")
        }

        if (
          document.documentElement.scrollTop > 200 &&
          element.dataset.visible === "false"
        ) {
          element.setAttribute("data-visible", "true")
        }
      })
    }

    handleScroll()

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <Box sx={{ paddingInline: 4, marginBlockStart: 7, marginBlockEnd: 7 }}>
        <Heading as="h1" sx={{ display: "inline", fontSize: ["lg", "xl"] }}>
          {pageTitle}
        </Heading>
        {pageDescription ? (
          <>
            <Text sx={{ fontSize: ["lg", "xl"], lineHeight: "heading" }}>
              &nbsp;–&nbsp;
            </Text>
            <Heading as="h2" sx={{ display: "inline", fontSize: ["lg", "xl"] }}>
              {pageDescription}
            </Heading>
          </>
        ) : null}
      </Box>
      {items?.length > 0 && hasTopGrid ? (
        <Box sx={{ display: ["none", "block"] }} className="js-grid">
          <UiCategoryGrid sx={{ marginBlockEnd: 45 }}>
            {items.map((item) => (
              <ShopTheLookVisualItem
                key={item.id}
                title={item.headline}
                handle={`#${kebabCase(item.headline)}`}
                image={item.assets?.[0]?.gatsbyImageData}
              />
            ))}
          </UiCategoryGrid>
        </Box>
      ) : null}
      <Box sx={{ display: [null, "flex"], alignItems: "flex-end" }}>
        <Box sx={{ flex: "0 0 50%" }}>
          {items.map((item) => (
            <ShopTheLookPhotos
              id={kebabCase(item.headline)}
              key={item.id}
              assets={item.assets}
              ref={(el) => {
                if (el && !shopTheLookPhotosRefs.current.includes(el)) {
                  shopTheLookPhotosRefs.current.push(el)
                }
              }}
            />
          ))}
        </Box>
        <Box
          className="js-mobile-content"
          data-visible="false"
          sx={{
            minHeight: [null, "100vh"],
            position: "sticky",
            bottom: 0,
            flex: "0 0 50%",
            transition: "transform .7s",
            "&[data-visible=false]": {
              transform: "translateY(100%)",
            },
          }}
        >
          <Box
            sx={{
              display: ["block", "flex"],
              flexDirection: [null, "column"],
              justifyContent: [null, "center"],
              width: "100%",
              height: [54, "unset"],
              minHeight: [null, "100vh"],
              backgroundColor: ["grayscale.100", "transparent"],
              marginInline: "auto",
            }}
          >
            {items.map((item) => (
              <ShopTheLookContent
                key={item.id}
                item={item}
                ref={(el) => {
                  if (el && !shopTheLookContentRefs.current.includes(el)) {
                    shopTheLookContentRefs.current.push(el)
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        className="js-back-to-top"
        as="button"
        sx={{
          color: "primary",
          display: ["none", "flex"],
          width: 13,
          height: 13,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "grayscale.white",
          cursor: "pointer",
          position: "fixed",
          bottom: 8,
          left: 8,
          border: 0,
          padding: 0,
          opacity: 0,
          visibility: "hidden",
          transition: "opacity .3s, visibility .3s",

          "&[data-visible=true]": {
            opacity: 1,
            visibility: "visible",
          },
        }}
        onClick={() => window.scrollTo(0, 0)}
        data-visible="false"
      >
        <Icon name="chevron-up" />
      </Box>
    </>
  )
}

export const ShopTheLookContent: React.FC<
  BoxProps & {
    item: ContentfulShopTheLook
  }
> = React.forwardRef(({ item, ...rest }, ref) => {
  const [selectedVariant, setSelectedVariant] = React.useState(null)
  const [openedProduct, setOpenedProduct] = React.useState(null)
  const [locked, setLocked] = useLockedBody(false)
  const [openedFeaturedProductsDrawer, setOpenedFeaturedProductsDrawer] =
    React.useState<null | string>(null)
  const [
    isFeaturedProductsSizeDrawerOpened,
    setIsFeaturedProductsSizeDrawerOpened,
  ] = React.useState(false)
  const [
    isMobileFeaturedProductsSizeDrawerOpened,
    setIsMobileFeaturedProductsSizeDrawerOpened,
  ] = React.useState(false)
  const [isNotifyMeOpen, setIsNotifyMeOpen] = React.useState(false)

  const { cart, createLineItem } = useStore()
  const {
    products: MedusaProducts,
    isLoading: MedusaProductLoading,
    isSuccess: MedusaProductsLoaded,
  } = useProducts(
    {
      id: item.products.map((product) => product.objectId),
      cart_id: cart?.id,
      region_id: cart?.region_id,
      currency_code: cart?.region?.currency_code,
      expand: "color,type,variants,variants.prices,categories",
    },
    {
      enabled: true,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error)
      },
    }
  )
  const { pushNotification, dismissNotification } = useNotificationContext()
  const { showCart } = useInterfaceContext()

  const productsData = React.useMemo(() => {
    if (!MedusaProducts?.length) {
      return []
    }

    return item?.products?.map((contentfulProduct) => {
      const product = MedusaProducts?.find(
        (p) => contentfulProduct.objectId === p.id
      )

      if (!product?.id) {
        return null
      }

      return {
        id: product.id,
        title: getProductTitleWithColor(product),
        price: formatProductPrice(product, cart?.region?.currency_code),
        image:
          contentfulProduct?.imagePacks?.[0]?.featuredImage?.gatsbyImageData,
        variants: product.variants,
        disableNotifyMe: contentfulProduct?.disableNotifyMe,
        url: `/product/${product.handle}`,
        categories: product.categories,
        primaryCategory: product?.categories?.find(
          (i) => i.id === product.primary_category_id
        )?.name,
        size_guides: contentfulProduct?.size_guides,
        type: product.type,
      }
    })
  }, [item.products, MedusaProducts])

  const onAddToCart = (notificationContent) => {
    pushNotification({
      id: "add-to-cart",
      body: (
        <>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            Added to cart
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {notificationContent}
          </Paragraph>
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("add-to-cart")
              showCart()
            }}
          >
            See the cart
          </Button>
        </>
      ),
      dismiss: {
        duration: 3000,
      },
    })
  }

  return (
    <Box
      ref={ref}
      sx={{
        opacity: "0",
        visibility: "hidden",
        transitionProperty: "opacity, visibility",
        transitionDuration: "400ms",
        transitionTimingFunction: "linear",
        "&[data-visible=true]": {
          opacity: "1",
          visibility: "visible",
          transitionDelay: "400ms",
        },
      }}
    >
      <Box
        {...rest}
        sx={{
          display: ["none", "flex"],
          flexDirection: "column",
          width: "100%",
          maxHeight: "100%",
          overflowY: "scroll",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Box
          sx={{
            maxWidth: 100,
            paddingBlock: 14,
            paddingInline: 4,
            marginInline: "auto",
          }}
        >
          <Heading sx={{ fontSize: "md", marginBlockEnd: 4 }}>
            {item.headline}
          </Heading>
          <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 11 }}>
            {item.subtitle}
          </Paragraph>
          <Flex sx={{ flexDirection: "column", gap: 4 }}>
            {productsData?.map(
              (product) =>
                !isProductOutOfStock(getVariantsInventory(product)) && (
                  <Flex
                    key={product?.id}
                    sx={{
                      alignItems: "center",
                      gap: [6, 10],
                      width: "100%",
                      overflow: "hidden",
                      ".item-button": {
                        visibility: [null, "hidden"],
                        opacity: [null, 0],
                        transition: "visibility .2s, opacity .1s",
                      },
                      "&:hover .item-button": {
                        visibility: "visible",
                        opacity: 1,
                      },
                      ".item-content": {
                        transform: [null, "translateY(36px)"],
                        transition: "transform .2s",
                      },
                      "&:hover .item-content": {
                        transform: "translateY(0)",
                      },
                    }}
                  >
                    {product?.image ? (
                      <Link to={product?.url} sx={{ flexShrink: 0 }}>
                        <Box as="picture" sx={{ flexShrink: 0 }}>
                          {product?.image?.images.sources.map(
                            (source, index) => (
                              <source
                                key={index}
                                type={source.type}
                                srcSet={getBunnyImageSrc(source.srcSet)}
                                sizes={source.sizes}
                              />
                            )
                          )}
                          <Image
                            width={product?.image?.width}
                            height={product?.image?.height}
                            sizes={product?.image?.images.fallback.sizes}
                            decoding="async"
                            loading="eager"
                            src={getBunnyImageSrc(
                              product?.image?.images.fallback.src
                            )}
                            srcSet={getBunnyImageSrc(
                              product?.image?.images.fallback.srcSet
                            )}
                            alt={product?.title}
                            sx={{
                              display: "block",
                              width: productsData.length > 3 ? 28 : 35,
                              height: "100%",
                              objectFit: "cover",
                              transform: "translateZ(0)",
                            }}
                          />
                        </Box>
                      </Link>
                    ) : null}
                    <Flex
                      className="item-content"
                      sx={{
                        flexDirection: "column",
                        flexGrow: 1,
                        gap: 2,
                        fontSize: "sm",
                      }}
                    >
                      <Paragraph
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {product.title}
                      </Paragraph>
                      <Paragraph>{product.price}</Paragraph>
                      <Box sx={{ marginBlockStart: 4 }} className="item-button">
                        {product?.variants.length < 2 ? (
                          <ProductButton
                            sx={{ width: "100%", maxWidth: 45 }}
                            hasNotifyMe={false}
                            isProductSoldOut={
                              isProductOutOfStock(
                                getVariantsInventory(product)
                              ) && Boolean(product?.disableNotifyMe)
                            }
                            isProductOutOfStock={isProductOutOfStock(
                              getVariantsInventory(product)
                            )}
                            isVariantSelected={Boolean(
                              product?.variants[0]?.id
                            )}
                            isVariantOutOfStock={isVariantOutOfStock(
                              product?.variants[0]
                            )}
                            isLoading={
                              createLineItem.isLoading || !MedusaProductsLoaded
                            }
                            isVariantSoldOut={
                              Boolean(product?.disableNotifyMe) &&
                              isVariantOutOfStock(product?.variants[0])
                            }
                            sizePickerVariant="single"
                            isGiftcard={false}
                            isMobile={false}
                            isInDrawer={false}
                            isProductRelated={false}
                            isProductSingleSize={product?.variants?.length < 2}
                            onClick={() => {
                              if (
                                !Boolean(product?.disableNotifyMe) &&
                                isVariantOutOfStock(product?.variants[0])
                              ) {
                                return
                              }

                              if (
                                (isProductOutOfStock(
                                  getVariantsInventory(product)
                                ) &&
                                  Boolean(product?.disableNotifyMe)) ||
                                (Boolean(product?.disableNotifyMe) &&
                                  isVariantOutOfStock(product?.variants[0]))
                              ) {
                                return
                              }

                              setLocked(false)
                              setOpenedFeaturedProductsDrawer(null)

                              handleAddToCart({
                                product: {
                                  title: product.title,
                                  subcategory: product.primaryCategory,
                                },
                                variant: {
                                  ...product.variants[0],
                                  quantity: 1,
                                },
                                cart: cart,
                                createLineItem: createLineItem,
                                onAddToCart: onAddToCart,
                              })
                            }}
                          />
                        ) : (
                          <Button
                            variant="ghost"
                            sx={{ width: "100%", maxWidth: 45 }}
                            onClick={() => {
                              setIsFeaturedProductsSizeDrawerOpened(true)
                              setOpenedProduct(product)
                              setLocked(true)
                            }}
                          >
                            Select size
                          </Button>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                )
            )}
          </Flex>
        </Box>
      </Box>
      <Box
        sx={{
          display: ["flex", "none"],
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          height: 54,
          position: "absolute",
          bottom: "0",
          fontSize: "sm",
          backgroundColor: "grayscale.100",
          paddingInline: 4,
          paddingBlock: 6,
        }}
      >
        {Boolean(item.headline) && (
          <Heading sx={{ fontSize: "sm", marginBlockEnd: 5 }}>
            {item.headline}
          </Heading>
        )}
        {Boolean(item.subtitle) && (
          <Paragraph sx={{ marginBlockEnd: 5 }}>{item.subtitle}</Paragraph>
        )}
        {!productsData?.every((product) =>
          isProductOutOfStock(getVariantsInventory(product))
        ) && (
          <UiButton
            variant="ghost"
            onClick={() => {
              setOpenedFeaturedProductsDrawer(item.id)
              setLocked(true)
            }}
          >
            View featured products
          </UiButton>
        )}
      </Box>
      {typeof window !== "undefined" ? (
        <>
          {createPortal(
            <>
              <Drawer
                isOpened={openedFeaturedProductsDrawer === item.id}
                onCloseClick={() => {
                  setOpenedFeaturedProductsDrawer(null)
                  setLocked(false)
                }}
                onBackdropClick={() => {
                  setOpenedFeaturedProductsDrawer(null)
                  setLocked(false)
                }}
                onSwipeRight={() => {
                  setOpenedFeaturedProductsDrawer(null)
                  setLocked(false)
                }}
              >
                <Flex
                  sx={{
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  {productsData?.map(
                    (product) =>
                      !isProductOutOfStock(getVariantsInventory(product)) && (
                        <Flex
                          key={product?.id}
                          sx={{
                            alignItems: "center",
                            gap: [6, 10],
                            width: "100%",
                            maxWidth: [null, "364px"],
                            ".item-button": {
                              visibility: [null, "hidden"],
                              opacity: [null, 0],
                              transition: "visibility .2s, opacity .2s",
                            },
                            "&:hover .item-button": {
                              visibility: "visible",
                              opacity: 1,
                            },
                            ".item-content": {
                              transform: [null, "translateY(36px)"],
                              transition: "transform .2s",
                            },
                            "&:hover .item-content": {
                              transform: "translateY(0)",
                            },
                          }}
                        >
                          {product?.image ? (
                            <Link to={product?.url} sx={{ flexShrink: 0 }}>
                              <Box as="picture" sx={{ flexShrink: 0 }}>
                                {product?.image?.images.sources.map(
                                  (source, index) => (
                                    <source
                                      key={index}
                                      type={source.type}
                                      srcSet={getBunnyImageSrc(source.srcSet)}
                                      sizes={source.sizes}
                                    />
                                  )
                                )}
                                <Image
                                  width={product?.image?.width}
                                  height={product?.image?.height}
                                  sizes={product?.image?.images.fallback.sizes}
                                  decoding="async"
                                  loading="lazy"
                                  src={getBunnyImageSrc(
                                    product?.image?.images.fallback.src
                                  )}
                                  srcSet={getBunnyImageSrc(
                                    product?.image?.images.fallback.srcSet
                                  )}
                                  alt={product?.title}
                                  sx={{
                                    display: "block",
                                    width: 35,
                                    height: "100%",
                                    objectFit: "cover",
                                    transform: "translateZ(0)",
                                  }}
                                />
                              </Box>
                            </Link>
                          ) : null}
                          <Flex
                            className="item-content"
                            sx={{
                              flexDirection: "column",
                              flexGrow: 1,
                              gap: 2,
                              fontSize: "sm",
                            }}
                          >
                            <Paragraph
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {product.title}
                            </Paragraph>
                            <Paragraph>{product.price}</Paragraph>
                            <Box
                              sx={{ marginBlockStart: 4 }}
                              className="item-button"
                            >
                              {product?.variants.length < 2 ? (
                                <ProductButton
                                  sx={{ width: "100%", maxWidth: 45 }}
                                  hasNotifyMe={false}
                                  isProductSoldOut={
                                    isProductOutOfStock(
                                      getVariantsInventory(product)
                                    ) && Boolean(product?.disableNotifyMe)
                                  }
                                  isProductOutOfStock={isProductOutOfStock(
                                    getVariantsInventory(product)
                                  )}
                                  isVariantSelected={Boolean(
                                    product?.variants[0]?.id
                                  )}
                                  isVariantOutOfStock={isVariantOutOfStock(
                                    product?.variants[0]
                                  )}
                                  isLoading={
                                    createLineItem.isLoading ||
                                    !MedusaProductsLoaded
                                  }
                                  isVariantSoldOut={
                                    Boolean(product?.disableNotifyMe) &&
                                    isVariantOutOfStock(product?.variants[0])
                                  }
                                  sizePickerVariant="single"
                                  isGiftcard={false}
                                  isMobile={false}
                                  isInDrawer={false}
                                  isProductRelated={false}
                                  isProductSingleSize={
                                    product?.variants?.length < 2
                                  }
                                  onClick={() => {
                                    if (
                                      !Boolean(product?.disableNotifyMe) &&
                                      isVariantOutOfStock(product?.variants[0])
                                    ) {
                                      return
                                    }

                                    if (
                                      (isProductOutOfStock(
                                        getVariantsInventory(product)
                                      ) &&
                                        Boolean(product?.disableNotifyMe)) ||
                                      (Boolean(product?.disableNotifyMe) &&
                                        isVariantOutOfStock(
                                          product?.variants[0]
                                        ))
                                    ) {
                                      return
                                    }

                                    setLocked(false)
                                    setOpenedFeaturedProductsDrawer(null)

                                    handleAddToCart({
                                      product: {
                                        title: product.title,
                                        subcategory: product.primaryCategory,
                                      },
                                      variant: {
                                        ...product.variants[0],
                                        quantity: 1,
                                      },
                                      cart: cart,
                                      createLineItem: createLineItem,
                                      onAddToCart: onAddToCart,
                                    })
                                  }}
                                />
                              ) : (
                                <Button
                                  variant="ghost"
                                  sx={{ width: "100%", maxWidth: 45 }}
                                  onClick={() => {
                                    setIsMobileFeaturedProductsSizeDrawerOpened(
                                      true
                                    )
                                    setOpenedProduct(product)
                                    setLocked(true)
                                  }}
                                >
                                  Select size
                                </Button>
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      )
                  )}
                  {typeof window !== "undefined"
                    ? createPortal(
                        <>
                          <SizePickerDrawer
                            variants={openedProduct?.variants}
                            product={openedProduct}
                            drawerProps={{
                              isOpened:
                                isMobileFeaturedProductsSizeDrawerOpened,
                              onCloseClick: () =>
                                setIsMobileFeaturedProductsSizeDrawerOpened(
                                  false
                                ),
                              onBackdropClick: () => {
                                setIsNotifyMeOpen(false)
                                setIsFeaturedProductsSizeDrawerOpened(false)
                                setIsMobileFeaturedProductsSizeDrawerOpened(
                                  false
                                )
                                setLocked(false)
                              },
                              onSwipeRight: () =>
                                setIsMobileFeaturedProductsSizeDrawerOpened(
                                  false
                                ),
                              sx: {
                                backgroundColor: "grayscale.white",
                                zIndex: 10001,
                              },
                            }}
                            onAddToCart={onAddToCart}
                            onNotifyMeClick={(variant) => {
                              setIsNotifyMeOpen(true)
                              setSelectedVariant(variant)
                              setLocked(true)
                            }}
                            onSizeGuideCloseClick={() => null}
                          />
                          <NotifyMeDrawer
                            isOpened={isNotifyMeOpen}
                            isNotifyOpenedFromDrawer={true}
                            selectedVariant={selectedVariant}
                            onCloseClick={() => setIsNotifyMeOpen(false)}
                            onBackdropClick={() => {
                              setIsNotifyMeOpen(false)
                              setIsFeaturedProductsSizeDrawerOpened(false)
                              setOpenedFeaturedProductsDrawer(null)
                              setLocked(false)
                            }}
                            sx={{ zIndex: 10002 }}
                          />
                        </>,
                        document.getElementById("drawers-root")
                      )
                    : null}
                </Flex>
              </Drawer>
              <SizePickerDrawer
                variants={openedProduct?.variants}
                product={openedProduct}
                drawerProps={{
                  isOpened: isFeaturedProductsSizeDrawerOpened,
                  onCloseClick: () => {
                    setIsFeaturedProductsSizeDrawerOpened(false)
                    setLocked(false)
                  },
                  onBackdropClick: () => {
                    setIsNotifyMeOpen(false)
                    setIsFeaturedProductsSizeDrawerOpened(false)
                    setIsMobileFeaturedProductsSizeDrawerOpened(false)
                    setLocked(false)
                  },
                  onSwipeRight: () => {
                    setIsFeaturedProductsSizeDrawerOpened(false)
                    setLocked(false)
                  },
                  sx: { backgroundColor: "grayscale.white" },
                }}
                onAddToCart={onAddToCart}
                onNotifyMeClick={(variant) => {
                  setIsNotifyMeOpen(true)
                  setSelectedVariant(variant)
                  setLocked(true)
                }}
                onSizeGuideCloseClick={() => null}
              />
            </>,
            document.getElementById("drawers-root")
          )}
        </>
      ) : null}
    </Box>
  )
})

export const ShopTheLookPhotos: React.FC<
  BoxProps & {
    assets: ContentfulShopTheLook["assets"]
  }
> = React.forwardRef(({ assets, ...rest }, ref) => (
  <Box {...rest} ref={ref}>
    {assets.map((asset) => (
      <Box key={asset.id} as="picture" sx={{ display: "block" }}>
        {asset?.gatsbyImageData?.images?.sources?.map((source, index) => (
          <source
            key={index}
            type={source.type}
            srcSet={getBunnyImageSrc(source?.srcSet)}
            sizes={source.sizes}
          />
        ))}
        <Image
          width={asset?.gatsbyImageData?.width}
          height={asset?.gatsbyImageData?.height}
          sizes={asset?.gatsbyImageData?.images?.fallback?.sizes}
          decoding="sync"
          src={getBunnyImageSrc(asset?.gatsbyImageData?.images?.fallback?.src)}
          srcSet={getBunnyImageSrc(
            asset?.gatsbyImageData?.images?.fallback?.srcSet
          )}
          alt=""
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </Box>
    ))}
  </Box>
))

export const ShopTheLookVisualItem: React.FC<
  BoxProps & {
    handle: string
    image?: IGatsbyImageData
    title: string
    size?: "default" | "large"
  }
> = ({ handle, image, title, size, sx, ...rest }) => (
  <Box
    {...rest}
    sx={{
      ...sx,
      gridColumn: size === "large" && "1 / span 2",
      gridRow: size === "large" && "span 2",
      "> a": {
        color: "primary",
        textDecoration: "none",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
        "&:hover .hover-image": {
          opacity: [0, 1],
          visibility: ["hidden", "visible"],
        },
      },
    }}
  >
    <a href={handle}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          aspectRatio: "4 / 5",
        }}
      >
        <Picture
          sources={image?.images.sources}
          aspectRatio="vertical"
          sx={{ height: "100%" }}
          imageProps={{
            width: image?.width,
            height: image?.height,
            sizes: image?.images?.fallback?.sizes,
            src: getBunnyImageSrc(image?.images?.fallback?.src),
            srcSet: getBunnyImageSrc(image?.images?.fallback?.srcSet),
            loading: "eager",
            alt: title,
          }}
        />
      </Box>
      {Boolean(title) && (
        <Heading
          as="h2"
          sx={{
            fontSize: "sm",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            paddingBlockStart: 2,
          }}
        >
          {title}
        </Heading>
      )}
    </a>
  </Box>
)
